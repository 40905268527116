// import './App.css';
import React from "react";
import SignUp from "./components/SignUp";
import { Tabs, Tab, makeStyles } from "@material-ui/core";

const TabPanel = props => {
    const { children, value, index, ...rest } = props;
    return <div
        role="tabpanel"
        hidden={value !== index}
        id={`tab-panel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...rest}
    >
        {children}
    </div>;
}

const useStyles = makeStyles(theme => ({
    tabs: {
        flexGrow: 1,
        // backgroundColor: "red",
    },
}));
function App() {
    const [ value, setValue ] = React.useState(0);
    const classes = useStyles();
    const handleChange = (e, v) => {
        setValue(v);
    }
    return (
        <div className="App">
            <header className="App-header">
                <h2>Tracker webapp...</h2>
            </header>
            <div className={classes.tabs}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Sign up" />
                    <Tab label="Sign in" />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <SignUp />
                </TabPanel>
            </div>
        </div>
    );
}

export default App;
