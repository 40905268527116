import React from "react";
import {
    CssBaseline, makeStyles,

    Container, Grid, Typography, TextField, Button, Link
 } from "@material-ui/core";

 const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: "red",
        maxWidth: "700px",
        // padding: theme.spacing(2),
        // margin: theme.spacing(1),
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    }
 }));

const SignUp = () => {
    const classes = useStyles();
    return <Container className={classes.root}>
        <CssBaseline />
        <div className={classes.paper}>
            <Typography component="h1" variant="h5">
                Sign up
            </Typography>
            <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            autoComplete="name"
                            name="name"
                            variant="outlined"
                            required
                            fullWidth
                            id="name"
                            label="Name"
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            autoComplete="email"
                            name="email"
                            variant="outlined"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            autoComplete="password"
                            name="password"
                            variant="outlined"
                            required
                            fullWidth
                            id="password"
                            type="password"
                            label="Password"
                        />
                    </Grid>
                    <Button
                        // fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >Sign up</Button>
                    <Grid container justify="flex-end">
                        <Link href="#" variant="body2">
                            Have an account already? Sign in
                        </Link>
                    </Grid>
                </Grid>
            </form>
        </div>
    </Container>;
};

export default SignUp;
